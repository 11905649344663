import {
    apiClient
} from '@/services/config.js'


export default {
    fetchNotifications() {
        return apiClient.get("/api/notifications/")
    },
    fetchCurrentsNotifications() {
        let ms = new Date().getTime() + 86400000
        let tomorrow = new Date(ms)
        let ms2 = new Date().getTime() - 86400000
        let yesterday = new Date(ms2)
 
        let sYesterday = yesterday.getFullYear() + "-" + (yesterday.getMonth() + 1) + "-" + yesterday.getDate()
        let sTomorrow  = tomorrow .getFullYear() + "-" + (tomorrow .getMonth() + 1) + "-" + tomorrow .getDate()

        return apiClient.get("/api/notifications/?validity.to>" + sYesterday + "&validity.from<" + sTomorrow)
    },
    setNotification(title, type, message, date, from, to, link) {
        return apiClient
            .post("/api/notifications", {
                title: title,
                type: type,
                message: message,
                event: {
                    date: date
                },
                validity: {
                    from: from,
                    to: to
                },
                link: link
            })
    },
    updateNotification(id,title, type, message, date, from, to, link) {
        return apiClient
        .put("/api/notifications/" + id, {
                title: title,
                type: type,
                message: message,
                event: {
                    date: date
                },
                validity: {
                    from: from,
                    to: to
                },
                link: link
            })
    },
    deleteNotification(id) {
        return apiClient
        .delete("/api/notifications/" + id)
    },

}