<template>
  <section class="app-main">
    <div class="container is-fluid is-marginless app-content">
      <div class="columns head is-vcentered">
        <div class="column is-offset-3 is-6 has-text-centered">
          <h3 class="title is-size-3 has-text-weight-normal">Administration</h3>
        </div>
        <div class="column is-3 is-hidden-mobile">
          <ol class="breadcrumb">
            <li>
              <a href="/#" class="is-active">Home</a>
            </li>
            <li>
              <span class="active">Administration</span>
            </li>
          </ol>
        </div>
      </div>
      <div class="animated">
        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <article class="tile is-child box">
              <div class="content">
                <p class="title">Messages</p>

                <table class="table">
                  <thead>
                    <tr>
                      <th>Action</th>
                      <th>Type</th>
                      <th>Titre</th>
                      <th>Date de création</th>
                      <th>Supprimer</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>
                        <button @click="prevPage" v-if="pageNumber>0">&larr;</button>
                      </th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>
                        <button @click="nextPage" v-if="pageNumber<pageCount-1">&rarr;</button>
                      </th>
                    </tr>
                  </tfoot>

                  <tbody>
                    <tr v-for="notif in paginatedData" v-bind:key="notif._id">
                      <td>
                        <span class="icon is-small" @click="showNotification(notif)">
                          <i class="fa fa-edit"></i>
                        </span>
                      </td>
                      <td>{{ notif.type }}</td>
                      <td>{{ notif.title }}</td>
                      <td>{{ notif.created_at | moment("dddd Do MMMM YYYY") }}</td>
                      <td>
                        <span class="icon is-small" @click="deleteNotification(notif)">
                          <i class="fa fa-trash"></i>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  Mode :
                  <span v-if="mode==='add'">Ajout</span>
                  <span v-if="mode==='update'">Modification</span>
                </p>
                <p>
                  <input type="hidden" name="id" v-model="notification._id" />
                  Type
                  <select v-model="notification.type">
                    <option value="event">AG</option>
                    <option value="urgency">urgence</option>
                  </select>
                  <span v-if="notification.type=='event'">
                    Date de l'événement
                    <input type="date" v-model="notification.event.date" />
                  </span>
                  <br />Affichage du
                  <input type="date" v-model="notification.validity.from" /> au
                  <input type="date" v-model="notification.validity.to" />
                  <br />Titre
                  <input type="text" v-model="notification.title" />
                  <br />Message
                  <br />
                  <textarea rows="5" cols="33" v-model="notification.message"></textarea>
                  <br />Lien
                  <input type="text" v-model="notification.link" />
                  <br />
                  <br />

                  <span class="button" @click="addNotification" v-if="mode==='add'">Ajouter</span>
                  <span
                    class="button"
                    @click="updateNotification"
                    v-if="mode==='update'"
                  >Enregistrer</span>
                  <span class="button" @click="mode='add'">Annuler</span>
                </p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import notificationServices from "@/services/notificationServices";

export default {
  name: "Admin",
  data: function() {
    return {
      mode: "add",
      mesinfos: "",
      notifications: [],
      pageNumber: 0,
      size: 5,
      token: localStorage.token,
      notification: {
        _id: 0,
        title: "",
        validity: {
          from: this.getIsoDate(this.today()).substring(0, 10),
          to: this.getIsoDate(this.today()).substring(0, 10)
        },
        message: "",
        event: {
          date: this.getIsoDate(this.today()).substring(0, 10),
          type: "AG",
          title: ""
        },
        type: "urgency",
        link: ""
      }
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    pageCount() {
      let l = this.notifications.length,
        s = this.size;
      return Math.ceil(l / s);
    },
    paginatedData() {
      const start = this.pageNumber * this.size,
        end = start + this.size;
      return this.notifications.slice(start, end);
    }
  },
  methods: {
    init: function() {
      notificationServices
        .fetchNotifications()
        .then(response => (this.notifications = response.data.notifications))
        .catch(() => {
          //console.log(error);
        });
        window.scrollTo(0,0);
    },
    nextPage: function() {
      this.pageNumber++;
    },
    prevPage: function() {
      this.pageNumber--;
    },
    toto: function() {
      alert("toto");
    },
    addNotification: function() {
      notificationServices
        .setNotification(
          this.notification.title,
          this.notification.type,
          this.notification.message,
          this.notification.event.date + "T00:00:00.000Z",
          this.notification.validity.from + "T00:00:00.000Z",
          this.notification.validity.to + "T00:00:00.000Z",
          this.notification.link
        )
        .then(() => (alert("Evenement ajouté"), this.init()));
    },
    deleteNotification(notif) {
      notificationServices
        .deleteNotification(notif._id)
        .then(
          this.notifications.splice(notif),
          alert("Evenement supprimé", this.init())
        );
    },
    showNotification(notif) {
      this.mode = "update";
      this.notification = notif;

      this.notification.validity.from =
        this.notification.validity.from != null
          ? this.notification.validity.from.substring(0, 10)
          : this.getIsoDate(this.today()).substring(0, 10);
      this.notification.validity.to =
        this.notification.validity.to.length != null
          ? this.notification.validity.to.substring(0, 10)
          : this.getIsoDate(this.today()).substring(0, 10);
      this.notification.event.date =
        this.notification.event.date.length != null
          ? this.notification.event.date.substring(0, 10)
          : this.getIsoDate(this.today()).substring(0, 10);
    },
    updateNotification() {
      notificationServices
        .updateNotification(
          this.notification._id,
          this.notification.title,
          this.notification.type,
          this.notification.message,
          this.notification.event.date + "T00:00:00.000Z",
          this.notification.validity.from + "T00:00:00.000Z",
          this.notification.validity.to + "T00:00:00.000Z",
          this.notification.link
        )
        .then(() => (alert("Evenement modifié"), this.init()));
    },
    getIsoDate(date) {
      try {
        var datearray = date.split("/");
        return new Date(
          datearray[1] + "/" + datearray[0] + "/" + datearray[2]
        ).toISOString();
      } catch {
        return null;
      }
    },
    getFormatedDate(date) {
      var d = new Date(date);
      return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    },
    today() {
      var d = new Date();
      return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    }
  }
};
</script>

<style lang="css" scoped>
.is-title {
  text-transform: capitalize;
}

.subtitle:not(:last-child),
.title:not(:last-child) {
  margin-bottom: 1.5rem;
}

.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.125;
}

.level-item .subtitle,
.level-item .title {
  margin-bottom: 0;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle {
  color: #2e2e2e;
  font-weight: 300;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
}

.subtitle strong,
.title strong {
  font-weight: 500;
}

.level-item {
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-preferred-size: auto; */
  flex-basis: auto;
  /* -ms-flex-positive: 0; */
  flex-grow: 0;
  /* -ms-flex-negative: 0; */
  flex-shrink: 0;
  /* -ms-flex-pack: center; */
  justify-content: center;
}

.breadcrumb {
  --separator: "\2044";
  list-style: none;
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-pack: end; */
  justify-content: flex-end;
}

.level-left .level-item:not(:last-child),
.level-right .level-item:not(:last-child) {
  margin-right: 0.75rem;
}

.is-vertical-center {
  text-align: center;
  padding-top: 10px;
}
</style>
